.container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 12vh;
  }
  .lovemain-text {
    white-space: pre-line!important;
  }
  .image-box {
    width: 300px;
    height: 400px;
    background-color: #F1F0EB;
    padding: 16px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
  }
  .image {
    width: 100%;
    height: 110%;
    margin-bottom: 16px;
  }
  .image-text{
    font-weight: 800 !important;
  }
  .lovebutton-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 4vh;
  }